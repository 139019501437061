import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AssetContractShared from "../../contracts/AssetContractShared.json";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "@redux/action/index";
import { contracts } from "src/utils/env";
import Loader from "@components/Loader/index";

const chain = "POLYGON";
const AddToWatchList = () => {
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.userDetails);

  const handleSubmit = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (
      window.web3.utils.toChecksumAddress(accounts[0]) !==
      window.web3.utils.toChecksumAddress(user.address)
    ) {
      dispatch(
        showToast({ type: "error", message: "Please connect correct wallet" })
      );
      return;
    }
    setIsLoading(true);
    window.web3.eth.handleRevert = true;
    const ContractInstance = new window.web3.eth.Contract(
      AssetContractShared,
      contracts[chain].AssetContractShared
    );
    try {
      const addTOWhiteList = await ContractInstance.methods
        .addToWhitelist(address)
        .send({ from: accounts[0] })
        .catch((err) => err);

      if (addTOWhiteList?.message) {
        // success
        const message = addTOWhiteList.message;
        dispatch(showToast({ type: "error", message: message }));
        setIsLoading(false);
      } else {
        dispatch(showToast({ type: "success", message: "Added To whitelist" }));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div style={{ display: "block" }}>
      <Loader isLoading={isLoading} />
      <input onChange={(e) => setAddress(e.target.value)} />
      <br /> 
      <Button
        size="large"
        variant="contained"
        onClick={() => handleSubmit(true)}
        color="primary"
      >
        Add TO whitelist{" "}
      </Button>
    </div>
  );
};

export default AddToWatchList;
