import React from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CollectionsIcon from "@mui/icons-material/Collections";
import AuthContext from "@context/AuthContext";
import PhotoIcon from "@mui/icons-material/Photo";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import logo from "@assets/Images/logo.png";
import { useSelector } from "react-redux";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CampaignIcon from "@mui/icons-material/Campaign";
import av from "src/assets/Images/av.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { imageHandler } from "./utils/formatter";

import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid } from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 0),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Navbar = ({ children }) => {
  const { user } = useSelector((state) => state.userDetails);
  const theme = useTheme();
  const navigate = useNavigate();
  // const [user, setUser] = useState();
  const [open, setOpen] = React.useState(true);
  const { isUserLogin } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const [activeLink, setActiveLink] = React.useState(null);

  const handleItemClick = (link) => {
    setActiveLink(link);
  };

  // Tabs Opan State
  const [entertainmentopen, setEntertainmentOpen] = React.useState(false);
  const [collectibleopen, setCollectibleOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  // useEffect(() => {
  //   dispatch(getUserDetails());
  // }, [isUserLogin]);

  const handleClickAway = () => {};

  if (!isUserLogin) {
    return <div>{children}</div>;
  } else
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          style={{ backgroundColor: "#4d5268" }}
          className="appbar"
          position="fixed"
          open={open}
        >
          <Toolbar className="header_content">
            <div className="header_logo">
              <IconButton
                style={{ color: "#FFFFFF  " }}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 1, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={logo}
                style={{
                  height: "48px",
                  background: "#4d5268",
                  cursor: "pointer",
                }}
                alt=""
                onClick={() => navigate("/dashboard")}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                className="adminProfile"
                src={user?.profile ? imageHandler(user?.profile, 7) : av}
                alt=""
              />
              <Typography className="username">
                {user?.first_name || "N/A"} {user?.last_name || "N/A"}
              </Typography>
              <Typography
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <div className="username">
                  <ExpandMoreIcon />
                </div>
              </Typography>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/profile" onClick={handleClose}>
                Profile
              </MenuItem>
              <MenuItem onClick={onSubmit}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader style={{ background: "#4d5268" }}>
            <img
              src={"/images/logoFull.png"}
              style={{
                height: "60px",
                width: "100%",
                background: "#4d5268",
                cursor: "pointer",
              }}
              alt=""
              onClick={() => navigate("/dashboard")}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <StyledLink
              to="/dashboard"
              isActive={activeLink === "/dashboard"}
              onClick={() => handleItemClick("/dashboard")}
            >
              <Grid
                container
                alignItems="flex-end"
                style={{
                  backgroundColor: activeLink === "/dashboard" ? "#1c248c" : "",
                  padding: 10,
                  marginRight: 10,
                  color: activeLink === "/dashboard" ? "white" : "",
                }}
              >
                <Grid item>
                  <ListItemIcon>
                    <DashboardIcon
                      style={{
                        color:
                          activeLink === "/dashboard" ? "white" : "#1c248c",
                      }}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  <ListItemText primary="Dashboard" />
                </Grid>
              </Grid>
            </StyledLink>
            <hr />

            <StyledLink
              to="/advertiser-list"
              isActive={activeLink === "/advertiser-list"}
              onClick={() => handleItemClick("/advertiser-list")}
            >
              <Grid
                container
                alignItems="flex-end"
                style={{
                  backgroundColor:
                    activeLink === "/advertiser-list" ? "#1c248c" : "",
                  padding: 10,
                  marginRight: 10,
                  color: activeLink === "/advertiser-list" ? "white" : "",
                }}
              >
                <Grid item>
                  <ListItemIcon>
                    <FeaturedVideoIcon
                      style={{
                        color:
                          activeLink === "/advertiser-list"
                            ? "white"
                            : "#1c248c",
                      }}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  <ListItemText primary="Advertiser" />
                </Grid>
              </Grid>
            </StyledLink>
            <hr />
            <StyledLink
              to="/campaign-list"
              isActive={activeLink === "/campaign-list"}
              onClick={() => handleItemClick("/campaign-list")}
            >
              <Grid
                container
                alignItems="flex-end"
                style={{
                  backgroundColor:
                    activeLink === "/campaign-list" ? "#1c248c" : "",
                  padding: 10,
                  marginRight: 10,
                  color: activeLink === "/campaign-list" ? "white" : "",
                }}
              >
                <Grid item>
                  <ListItemIcon>
                    <CampaignIcon
                      style={{
                        color:
                          activeLink === "/campaign-list" ? "white" : "#1c248c",
                      }}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  <ListItemText primary="Campaign" />
                </Grid>
              </Grid>
            </StyledLink>
            <hr />
            <StyledLink
              to="/profile"
              isActive={activeLink === "/profile"}
              onClick={() => handleItemClick("/profile")}
            >
              <Grid
                container
                alignItems="flex-end"
                style={{
                  backgroundColor: activeLink === "/profile" ? "#1c248c" : "",
                  padding: 10,
                  marginRight: 10,
                  color: activeLink === "/profile" ? "white" : "",
                }}
              >
                <Grid item>
                  <ListItemIcon>
                    <AccountBoxIcon
                      style={{
                        color: activeLink === "/profile" ? "white" : "#1c248c",
                      }}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  <ListItemText primary="Profile" />
                </Grid>
              </Grid>
            </StyledLink>
            <hr />
            <StyledLink
              to="/setting"
              isActive={activeLink === "/setting"}
              onClick={() => handleItemClick("/setting")}
            >
              <Grid
                container
                alignItems="flex-end "
                style={{
                  backgroundColor: activeLink === "/setting" ? "#1c248c" : "",
                  padding: 10,
                  marginRight: 10,
                  color: activeLink === "/setting" ? "white" : "",
                }}
              >
                <Grid item>
                  <ListItemIcon>
                    <SettingsIcon
                      style={{
                        color: activeLink === "/setting" ? "white" : "#1c248c",
                      }}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  <ListItemText primary="Settings" />
                </Grid>
              </Grid>
            </StyledLink>
            <hr />
            <StyledLink to="/logout">
              <Grid
                container
                alignItems="flex-end"
                style={{
                  padding: 10,
                  marginRight: 10,
                }}
              >
                <Grid item>
                  <ListItemIcon>
                    <LogoutIcon
                      style={{
                        color: "#1c248c",
                      }}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  <ListItemText primary="Logout" onClick={onSubmit} />
                </Grid>
              </Grid>
            </StyledLink>
            <hr />
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    );
};
export default Navbar;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #042464;
  display: flex;
  margin-left: 15px;
  margin-bottom: 12px;
  margin-top: 12px;
`;
