const ENV = {
  BASE_URL:
    process.env.NODE_ENV === "development"
      ? "https://api.amadz.com"
      : "https://api.amadz.com",
  // "http://localhost:9005"
};

export const contracts = {
  POLYGON: {
    WyvernProxyRegistry: "0xb576B7FF9aD2F5beD6f76aFFE3B93178bc14EB5a",
    WyvernExchange: "0x21A2ee37A4DB036D0ae99891f17d01386172FC4f",
    WyvernTokenTransferProxy: "0xf7Fca1Be27Aff988A7D2b6A9e71289FD2af3913C",
    AssetContractShared: "0xd1C649ffC6fa9CbaC480089BAa9FC7f5D8feDAF8",
    RentalMarket: "0xa3e9365ce905767DE25c0f622060783F761504F0",
  },
};

export const feeRecipient = "0xd2645b8E1DE56d53236bd0EB2728Efa9Ae710003";
export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const noAddress = null;

/**
 * Deployed exchange contract on network
 */
export const chainId = {
  ETHEREUM: "0x3",
  POLYGON: "0x13881",
};

export const tokenAddress = {
  ETHEREUM: {
    ETH: zeroAddress,
    WETH: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
  },
  POLYGON: {
    MATIC: zeroAddress,
    WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
  },
};

// export const noAddress = null

export default ENV;
