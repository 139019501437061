import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import React, { useState, useEffect, lazy, Suspense } from "react";
import Navbar from "./Navbar";
import { HIDE_TOAST } from "@redux/action/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "@pages/SignIn";
import { Routes as RouteWrapper, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { getUserDetails } from "@redux/action";
import AuthContext from "@context/AuthContext";
import Loader from "@components/Loader/index";
// import EntertainmentPacks from "./containers/entertainmentpacks/index";
// import CreateAndUpadateEntertainmentPacks from "./containers/entertainmentpacks/createEntertainmentPacks";
// import Agency from "./containers/agency/index";
// import CreateAndUpdateAgency from "./containers/agency/createAndUpdateAgency";
// import Movies from "./containers/movies/index";
// import CreateAndUpdateMovie from "./containers/movies/createAndUpdateMovie";
// import CreateAndUpdateHero from "./containers/superHeroes/createAndUpdateSuperhero";
// import SuperHeroes from "./containers/superHeroes/index";
// import MovieHeroes from "./containers/movies/movieHeros";
// import AssociateHero from "./containers/movies/associateHero";
import Advertisement from "./containers/Advertisement/index";
import Advertisementverify from "./containers/Advertisement/Advertisementverify";
import AddToWatchList from "./containers/AddToWatchList/index";
import Influencer from "./containers/Influencer/index";
import InfluencerDetails from "./containers/Influencer/InfluencerDetails";

const Dashboard = lazy(() => import("@containers/dashboard"));
const ForgetPassword = lazy(() => import("@pages/ForgetPassword"));
const ResetPassword = lazy(() => import("@pages/ResetPassword"));
const NotFound = lazy(() => import("@pages/NotFound"));
const Profile = lazy(() => import("@pages/Profile"));
// const Tournament = lazy(() => import('@containers/tournament'))
// const Player = lazy(() => import("@containers/player"));
// const Teams = lazy(() => import("@containers/teams"));
// const CreateAndUpdateTournament = lazy(() => import('@containers/tournament/createAndUpdateTournament'));
// const EarningsSales = lazy(() => import("@containers/earningsSales"));
// const CreateAndUpadatTeam = lazy(() => import("@containers/teams/createAndUpadatTeam"));
// const FanCard = lazy(() => import("@containers/fanCard/index"));
// const CollectiblePacks = lazy(() => import("@containers/collectiblePacks/index"));
// const AddPlayer = lazy(() => import("./containers/player/addPlayer"));
// const TeamPlayer = lazy(() => import("./containers/teams/teamPlayer"))
// const AssociatePlayer = lazy(() => import("./containers/teams/associatePlayer"));
// const CreateFanCard = lazy(() => import("./containers/fanCard/createFanCard"));
// const CreateCollectiblePack = lazy(() => import('@containers/collectiblePacks/createCollectiblePack'));
// const UploadTeamAsset = lazy(() => import('@containers/teams/uploadTeamAsset'));
// const UserCardRank =lazy(()=>import('@containers/userCardRank'))

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    isUserLogin: false,
    token: "",
    user_id: "",
  });
  const location = useLocation();

  useEffect(() => {
    if (localStorage.hasOwnProperty("user")) {
      const USER = localStorage.getItem("user");
      setUser((prev) => {
        return {
          ...prev,
          isUserLogin: true,
          token: USER.token,
          user: USER.user,
        };
      });
      dispatch(getUserDetails());
    }
  }, []);

  useEffect(() => {
    if (user) dispatch(getUserDetails());
  }, [user]);

  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <AuthContext.Provider
        value={{
          ...user,
          setUser,
        }}
      >
        <Suspense fallback={<Loader isLoading={true} />}>
          <Navbar>
            <RouteWrapper>
              <Route exact path="/" element={<SignIn />} />
              <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
              />
              <Route
                exact
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/dashboard"
                exact
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/setting"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/advertiser-list"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Advertisement />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/campaign-list"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Influencer /> {/*  this is an Advertiser List */}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/influencer-details/:userId"
                element={
                  <ProtectedRoute>
                    <InfluencerDetails />
                  </ProtectedRoute>
                }
              />

              {/* <Route
                path="/add-to-whitelist"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <AddToWatchList />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/advertiser-details/:userId"
                element={
                  <ProtectedRoute>
                    <Advertisementverify />
                  </ProtectedRoute>
                }
              />

              <Route path="/*" element={<NotFound />} />
            </RouteWrapper>
          </Navbar>
        </Suspense>
      </AuthContext.Provider>
    </>
  );
};

export default App;
