import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { imageHandler } from "src/utils/formatter";
import { Container, Box, Button, Grid, Chip, Stack } from "@material-ui/core";
import AssetContractShared from "../../contracts/AssetContractShared.json";
import { useNavigate } from "react-router-dom";
import { getApiReq, postApiReq } from "src/utils/ApiHandlers";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { contracts, chainId } from "@utils/env";
import { showToast } from "@redux/action";
import Loader from "@components/Loader/index";
import { useSelector } from "react-redux";
import av from "src/assets/Images/av.jpg";

const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  width: "100%",
  height: "auto",
  padding: "15px 5px",
  borderColor: "grey.500",
};
const commonStyles1 = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  width: "100%",
  height: "auto",
  padding: "15px 5px",
  borderColor: "grey.500",
};
const chain = "POLYGON";

export default function Advertisementverify() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verify, setVerifydata] = useState([]);
  let params = useParams();
  const [status, setStatus] = useState();
  const { user } = useSelector((state) => state.userDetails);
  let { userId } = params;

  const handleSubmit = async () => {
    if (!verify.brandName) {
      dispatch(
        showToast({
          type: "error",
          message: "Brand url is required for verify advertiser",
        })
      );
      console.log("inside");
      return true;
    }

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId[chain] }], // chainId must be in hexadecimal numbers
      });
    } catch (error) {
      if (error) {
        dispatch(
          showToast({
            type: "error",
            message: error.message,
          })
        );
      }
      return false;
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (
      window.web3.utils.toChecksumAddress(accounts[0]) !==
      window.web3.utils.toChecksumAddress(user.address)
    ) {
      dispatch(
        showToast({ type: "error", message: "Please connect correct wallet" })
      );
      return;
    }
    setIsLoading(true);
    window.web3.eth.handleRevert = true;
    const ContractInstance = new window.web3.eth.Contract(
      AssetContractShared,
      contracts[chain].AssetContractShared
    );
    try {
      let addTOWhiteList;

      if (status == 0) {
        addTOWhiteList = await ContractInstance.methods
          .addToWhitelist(verify.address)
          .send({ from: accounts[0] })
          .catch((err) => err);
      } else {
        addTOWhiteList = await ContractInstance.methods
          .removeFromWhitelist(verify.address)
          .send({ from: accounts[0] })
          .catch((err) => err);
      }
      if (addTOWhiteList?.message) {
        // success
        const message = addTOWhiteList.message;
        dispatch(showToast({ type: "error", message: message }));
        setIsLoading(false);
      } else {
        postApiReq("/admin/verify-advertiser", { id: userId })
          .then((res) => {
            if (res.status) {
              setIsLoading(false);
              dispatch(
                showToast({
                  type: "success",
                  message: `Adviser ${
                    status == 0 ? "Approved" : "Blocked "
                  } successfully`,
                })
              );
              navigate("/advertiser-list");
            } else {
              setIsLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  function Back() {
    navigate(-1);
  }

  const getuserVerify = (userId) => {
    getApiReq(`/admin/get-advertiser-details/${userId}`)
      .then((res) => {
        if (res) {
          setVerifydata(res.data);
          setIsLoading(false);
          setStatus(res.data.status);
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getuserVerify(userId);
  }, []);

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Loader isLoading={isLoading} />
        <Typography className={styles.heading}>Advertiser details</Typography>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        ></Box>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Box className={styles.profilebox}>
              <Typography
                sx={{ mx: 2 }}
                variant="button"
                display="block"
                gutterBottom
              >
                <Box>Logo</Box>
                <Box>
                  <img
                    className={styles.tempImage}
                    alt="temp"
                    src={imageHandler(verify.profile, 7) || av}
                  />
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box className={styles.profilebox}>
              <Typography
                sx={{ mx: 2 }}
                variant="button"
                display="block"
                gutterBottom
              >
                <Box>Banner</Box>
                <Box>
                  <img
                    alt="temp"
                    className={styles.tempBannerImage}
                    src={imageHandler(verify.banner, 8) || av}
                  />
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Container fixed>
          <Grid container spacing={1}>
            <Grid item xs={5.5} sx={{ m: 1 }}>
              <TextField
                fullWidth
                id="outlined-read-only-input"
                label="FIRST NAME"
                defaultValue={"verify.first_name"}
                value={verify.first_name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={5.5} sx={{ m: 1 }}>
              <TextField
                fullWidth
                id="outlined-read-only-input"
                label="LAST NAME"
                defaultValue={"verify.first_name"}
                value={verify.last_name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={5.5} sx={{ m: 1 }}>
              <TextField
                fullWidth
                id="outlined-read-only-input"
                label="Wallet Address"
                defaultValue={"verify.address"}
                value={verify.address}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={5.5} sx={{ m: 1 }}>
              <TextField
                fullWidth
                id="outlined-read-only-input"
                label="Email Address"
                defaultValue={"verify.address"}
                value={verify.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={5.5} sx={{ m: 1 }}>
              <TextField
                fullWidth
                id="outlined-read-only-input"
                label="Brand Name"
                defaultValue={"verify.address"}
                value={verify.brandName || ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mx: 2, color: "#042464" }}
                variant="button"
                display="block"
                gutterBottom
              >
                Advertiser Business Type
              </Typography>
              <Box
                sx={{ ...commonStyles1, border: 0.5, borderColor: "grey.500" }}
              >
                <Grid className={styles.tagschip}>
                  {verify.businessType?.map((e, value) => (
                    <Stack direction="row" spacing={1}>
                      <Chip label={e} />
                    </Stack>
                  ))}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{ mx: 2 }}
                variant="button"
                display="block"
                gutterBottom
              >
                # Tags
              </Typography>
              <Box
                sx={{ ...commonStyles, border: 0.5, borderColor: "grey.500" }}
              >
                <Grid className={styles.tagschip}>
                  {verify.tags?.map((e, value) => (
                    <Stack direction="row" spacing={1}>
                      <Chip label={e} />
                    </Stack>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container fixed>
          <Box sx={{ my: 3 }}>
            <Button sx={{ mx: 1 }} variant="string" onClick={Back}>
              BACK
            </Button>
            {status === 0 ? (
              <Button
                size="large"
                variant="contained"
                onClick={handleSubmit}
                color="success"
              >
                Verify{" "}
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                onClick={handleSubmit}
                color="error"
              >
                {" "}
                Block{" "}
              </Button>
            )}
          </Box>
        </Container>
      </TableContainer>
    </React.Fragment>
  );
}
