import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "@components/Loader";
import { useNavigate } from "react-router-dom";
import { getApiReq } from "src/utils/ApiHandlers";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { imageHandler } from "src/utils/formatter";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Advertisement() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState(0);

  const AdvertisementPending = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-advertiser?status=${value}`)
      .then((res) => {
        if (res) {
          setData(res.data);
          setIsLoading(false);
          console.log(res.data, "data");
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    AdvertisementPending();
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <div className={styles.searchalign}>
          <Typography className={styles.heading}>Advertiser</Typography>
          <Box sx={{ height: 10 }}>
            <Card style={{ boxShadow: "none" }}>
              <CardContent>
                <Grid container alignItems=" start">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      className={styles.TextFieldindex}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                      variant="outlined"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              "& .MuiTabs-indicator": {
                backgroundColor: "#35a5f3",
              },
            }}
            className={styles.tabbox}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="disabled  tabs example"
            >
              <Tab
                sx={{ color: "#042464" }}
                label="PENDING"
                {...a11yProps(0)}
              />
              <Tab sx={{ color: "#042464" }} label="VERIFY" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel>
            <Table
              className="advertise"
              sx={{ minWidth: 650, padding: "0px" }}
              aria-label="simple table"
            >
              <TableHead className={styles.tblehead}>
                <TableRow>
                  <TableCell className={styles.tabletitle}>#</TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    Profile Image
                  </TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    Name
                  </TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    Last Name
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    className={styles.tabletitle}
                    align="center"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    className={styles.tabletitle}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow
                    className={styles.tableRow}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell className={styles.dataView} align="center">
                      <img
                        className={styles.roundImg}
                        src={imageHandler(item.profile, 7) || "/images/av.png"}
                        alt=""
                      />
                    </TableCell>
                    <TableCell className={styles.dataView} align="center">
                      {item.first_name || "unknown"}
                    </TableCell>
                    <TableCell className={styles.dataView} align="center">
                      {item.last_name || "unknown"}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      className={styles.dataView}
                      align="center"
                    >
                      {item.email}
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      className={styles.dataView}
                      align="center"
                    >
                      {" "}
                      <Button
                        onClick={() =>
                          navigate(`/advertiser-details/${item._id}`)
                        }
                      >
                        <VisibilityIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {data?.length < 1 && (
              <Box align={"center"} w="100%" p={5}>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="textSecondary"
                >
                  Data not Available
                </Typography>
              </Box>
            )}
          </TabPanel>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
