import axios from "axios";
import ENV from './env';

const responseFormatter = (status, data, error) => {
  return { status, data, error };
}

export const postApiReq = async (url, data) => {
  const route_url = ENV.BASE_URL + url;
  var USER = null
  if (localStorage.hasOwnProperty('user')) {
    USER = JSON.parse(localStorage.getItem('user'))
  }
  return await axios.post(route_url, data, {
    headers: USER?.token ? {
      Accept: "application/json",
      Authorization: `Bearer ${USER.token}`,
    } :
      {
        Accept: "application/json",
      },
  })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/'
      }
      if (e.response.data.length > 0) {
        return responseFormatter(false, null, e.response.data)
      } else {
        return responseFormatter(false, null, e.response.data)
      }
    });
}

export const deleteApiReq = async (url) => {
  const route_url = ENV.BASE_URL + url;
  var USER = null
  if (localStorage.hasOwnProperty('user')) {
    USER = JSON.parse(localStorage.getItem('user'))
  }
  return await axios.delete(route_url, {
    headers: USER?.token ? {
      Accept: "application/json",
      Authorization: `Bearer ${USER.token}`,
    } :
      {
        Accept: "application/json",
      },
  })
}



export const getApiReq = async (url) => {
  var USER = null
  if (localStorage.hasOwnProperty('user')) {
    USER = JSON.parse(localStorage.getItem('user'))
  }
  const route_url = ENV.BASE_URL + url;
  console.log(route_url)
  return await axios.get(route_url,
    {
      headers: USER?.token ? {
        Accept: "*/*",
        Authorization: `Bearer ${USER.token}`,
      } :
        {
          Accept: "*/*",
        },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/'
      }
      else
        if (e.response.data.length > 0) {
          return responseFormatter(false, null, e.response.data)
        } else {
          return responseFormatter(false, null, e.response.data)
        }
    });
}


export const putApiReq = async (url, data) => {
  var USER = null
  if (localStorage.hasOwnProperty('user')) {
    USER = JSON.parse(localStorage.getItem('user'))
  }

  const route_url = ENV.BASE_URL + url;
  return await axios.put(route_url,
    data,
    {
      headers: USER?.token ? {
        Accept: "application/json",
        Authorization: `Bearer ${USER.token}`,
      } :
        {
          Accept: "application/json",
        },
    }
  ).then((response) => {
    return responseFormatter(true, response.data, null);
  })
    .catch(e => {
      if (e.response.data.length > 0) {
        return responseFormatter(false, null, e.response.data)
      } else {
        return responseFormatter(false, null, e.response.data)
      }
    });
}

export const getReq = async (url) => {
  const route_url = ENV.BASE_URL + url;
  return await axios.get(route_url,
    {
      headers:
      {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e) {
        return responseFormatter(false, null, e?.response?.data || null)
      } else {
        return responseFormatter(false, null, e?.response?.data || null)
      }
    });
}


export const getImageUrl = async (data) => {
  var image = false
  await postApiReq("/user/upload-asset", data)
    .then((res) => {
      if (res.status) {
        image = res.data
      }
      else {
        image = false
      }
    }
    )
    .catch((e) => {
      console.log(e)
    })

  return image
}