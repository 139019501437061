import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "@components/Loader";
import { useNavigate } from "react-router-dom";
import { deleteApiReq, getApiReq } from "src/utils/ApiHandlers";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { imageHandler } from "src/utils/formatter";

import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Influencer() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [refreshList, setRefreshList] = useState(true);
  const [value, setValue] = React.useState(0);

  const getInfluencerList = () => {
    console.log(value);
    setIsLoading(true);
    getApiReq(
      `/admin/get-allcampaign/${value}?rows=16&offset=0&search=${search}`
    )
      .then(async (res) => {
        if (res) {
          setData(res.data);
          setIsLoading(false);
          // const response = await fetch('https://ipinfo.io/json?token=d06edc027e1316');
          // const data = await response.json();
          // console.log(data, "data");
        } else {
          dispatch(showToast({ message: res.error, type: "error" }));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteCampaign = (campaignId) => {
    try {
      setIsLoading(true);
      deleteApiReq(`/admin/delete-campaign/${campaignId}`);

      dispatch(
        showToast({ message: "advertiser campaign deleted", type: "success" })
      );
      setRefreshList(!refreshList);
      setIsLoading(true);
    } catch (error) {
      setIsLoading(true);
      dispatch(
        showToast({ message: "failed !! advertiser delete", type: "error" })
      );
      console.log(error);
    }
  };
  useEffect(() => {
    getInfluencerList();
  }, [value, search, refreshList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <div className={styles.searchalign}>
          <Typography className={styles.heading}>Campaign</Typography>
          <Box>
            <Card style={{ boxShadow: "none" }}>
              <CardContent>
                <Grid container spacing={3} alignItems=" start">
                  <Grid item xs={12} lg={12} className="searchbar">
                    <TextField
                      className={styles.TextFieldindex}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px",
                        },
                      }}
                      placeholder="Search"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              "& .MuiTabs-indicator": {
                backgroundColor: "#35a5f3",
              },
            }}
            className={styles.tabbox}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="disabled  tabs example"
            >
              <Tab sx={{ color: "#042464" }} label="PAST" {...a11yProps(0)} />
              <Tab
                sx={{ color: "#042464" }}
                label="PRESENT"
                {...a11yProps(1)}
              />
              <Tab sx={{ color: "#042464" }} label="FUTURE" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel>
            <Table
              className="campaign"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className={styles.tblehead}>
                <TableRow>
                  <TableCell className={styles.tabletitle}>#</TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    Campaign Name
                  </TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    Start Date
                  </TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    End Date
                  </TableCell>
                  <TableCell className={styles.tabletitle} align="center">
                    Level
                  </TableCell>
                  <TableCell
                    colSpan={5}
                    className={styles.tabletitle}
                    align="center"
                  >
                    Type
                  </TableCell>
                  <TableCell
                    colSpan={5}
                    className={styles.tabletitle}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow
                    className={styles.tableRow}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell className={styles.dataView} align="center">
                      {item.name}
                    </TableCell>
                    <TableCell className={styles.dataView} align="center">
                      {new Date(item.from * 1000).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell className={styles.dataView} align="center">
                      {new Date(item.to * 1000).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      className={styles.dataView}
                      align="center"
                    >
                      {item.leveltype + " - " + item.levelname}
                    </TableCell>
                    <TableCell
                      colSpan={4}
                      className={styles.dataView}
                      align="center"
                    >
                      {item.campaign_type}
                      {/* <Button
                      // onClick={() =>
                      //   navigate(`/influencer-details/${item._id}`)
                      // }
                      >
                                <EditSharpIcon />
                      <DeleteForeverSharpIcon />
                      <VisibilityIcon />
                      </Button> */}
                    </TableCell>

                    <TableCell
                      sx={{ desplay: "flex" }}
                      // colSpan={2}
                      className={styles.dataView}
                      // align="center"
                    >
                      <Box sx={{ display: "flex" }}>
                        <Button>
                          {" "}
                          <VisibilityIcon />
                        </Button>
                        {/* <Button> <EditSharpIcon /></Button> */}
                        <Button
                          onClick={(e) => {
                            handleDeleteCampaign(item._id);
                          }}
                        >
                          {" "}
                          <DeleteForeverSharpIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {data?.length < 1 && (
              <Box align={"center"} w="100%" p={5}>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="textSecondary"
                >
                  Data not Available
                </Typography>
              </Box>
            )}
          </TabPanel>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
